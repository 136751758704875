import React, { useState, useEffect } from "react";
import * as signalR from "@microsoft/signalr";
import routerService from "../Services/routerService";
import "./routerstatus.css";
import { OverlayTrigger, Tooltip, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function RouterStatus({
  userAppMetaData,
  lightTheme,
  agencyProfile,
  user,
  chosenAgency,
}) {
  const [agencyRouters, setAgencyRouters] = useState({
    main: [],
    components: [],
    uniqueIds: [],
  });
  const [routers, setRouters] = useState({ main: [], components: [] });
  const [updatedRouter, setUpdatedRouter] = useState({ main: [] });
  const [updatedAgenciesRegistry, setAgenciesRegistry] = useState({ main: [] });

  useEffect(() => {
    const fetchData = async () => {
      const currentDate = new Date().toLocaleString();
      console.log(`-----------${currentDate}----------`);
      const payload = {
        agencyId: chosenAgency !== null ? chosenAgency : agencyProfile?.id,
      };
      routerService
        .getAllRouterRegistries(payload, userAppMetaData?.admToken)
        .then((response) => {
          setAgencyRouters((prevState) => {
            const copy = { ...prevState };
            copy.main = response.data.routers;
            copy.components = response.data.routers;
            copy.uniqueIds = response.data.uniqueIds;
            return copy;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchData();

    const intervalId = setInterval(fetchData, 420000);

    return () => clearInterval(intervalId);
  }, [userAppMetaData?.admToken, agencyProfile?.id, chosenAgency]);

  useEffect(() => {
    if (agencyRouters?.uniqueIds?.length > 0) {
      const payload = { uuids: agencyRouters.uniqueIds };
      routerService
        .getAllRouters(payload, userAppMetaData?.admToken)
        .then((response) => {
          setRouters((prevState) => {
            const copy = { ...prevState };
            copy.main = response.data;
            copy.components = response.data;
            return copy;
          });
        })
        .catch((error) => {
          console.log("ERROR", error);
        });
    }
  }, [agencyRouters.uniqueIds, userAppMetaData?.admToken]);

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl("https://cpreportsjstrigger.azurewebsites.net/api", {
        hubName: "cprouter",
      })
      .withAutomaticReconnect()
      .build();

    connection
      .start()
      .then((result) => {
        console.log("Connected!", result);

        connection.on("SendUpdatedDocument", (message) => {
          setUpdatedRouter((prevState) => {
            const copy = { ...prevState };
            copy.main = message;
            return copy;
          });
        });
        connection.on("AgenciesRoutersConnection", (message) => {
          if (
            message.agencyId ===
            (chosenAgency !== null ? chosenAgency : agencyProfile?.id)
          ) {
            setAgenciesRegistry((prevState) => {
              const copy = { ...prevState };
              copy.main = message;
              return copy;
            });
          }
        });
      })
      .catch((e) => console.log("Connection failed: ", e));
  }, [agencyProfile?.id, chosenAgency]);

  useEffect(() => {
    setAgencyRouters((prevState1) => {
      const copy = { ...prevState1 };

      // make sure objs2 is an array
      routers?.main.forEach((obj2) => {
        const index = copy.main.findIndex(
          (obj1) => `${obj1.id}-healthMsg` === obj2.uuid
        );
        if (index !== -1) {
          copy.main[index] = { ...obj2, ...copy.main[index] };
        }
      });
      copy.components = copy.main;
      return copy;
    });
  }, [routers?.main]);

  useEffect(() => {
    setAgencyRouters((prevState1) => {
      const copy = { ...prevState1 };

      const { id, ...newObj2 } = updatedRouter?.main;

      const index = copy.main.findIndex(
        (obj1) => `${obj1.id}-healthMsg` === newObj2.uuid
      );
      if (index !== -1) {
        copy.main[index] = { ...copy.main[index], ...newObj2 };
      }
      copy.components = copy.main;

      return copy;
    });
  }, [updatedRouter?.main]);

  useEffect(() => {
    function mergeStates() {
      setAgencyRouters((prevState1) => {
        const copy = { ...prevState1 };

        const obj2 = updatedAgenciesRegistry?.main;
        const index = copy.main.findIndex((obj1) => obj1.id === obj2.id);
        if (index === -1) {
          copy.main.push(obj2);
        }
        copy.components = copy.main;
        return copy;
      });
    }

    mergeStates();
  }, [updatedAgenciesRegistry?.main]);

  const renderTooltip = () => {
    return (
      <Tooltip
        id=""
        className={lightTheme ? "custom-tooltip-light" : "custom-tooltip-dark"}
      >
        {agencyRouters?.components
          ?.filter(
            (router) =>
              !router?.bluetoothServiceStatus ||
              !router?.jarServiceStatus ||
              !router?.jarProcessStatus ||
              !router?.controllerStatus
          )
          .map((router, index) => (
            <Col
              key={index}
              className="poppins-medium-romance-14px"
              style={{
                color: lightTheme ? "black" : "white",
              }}
            >
              {router?.friendlyName}
            </Col>
          ))}
      </Tooltip>
    );
  };

  const renderTooltipActive = () => {
    return (
      <Tooltip
        id=""
        className={lightTheme ? "custom-tooltip-light" : "custom-tooltip-dark"}
      >
        {agencyRouters?.components
          ?.filter(
            (router) =>
              router?.bluetoothServiceStatus ||
              router?.jarServiceStatus ||
              router?.jarProcessStatus ||
              router?.controllerStatus
          )
          .map((router, index) => (
            <Col
              key={index}
              className="poppins-medium-romance-14px"
              style={{
                color: lightTheme ? "black" : "white",
              }}
            >
              {router?.friendlyName}
            </Col>
          ))}
      </Tooltip>
    );
  };
  return (
    <React.Fragment>
      {user?.userroles?.includes("4SightAdmin") && chosenAgency !== null ? (
        <Link
          to={`/admin/agency/${chosenAgency}/list_of_agencys_routers`}
          style={{ textDecoration: "none" }}
        >
          <div className="group-861 mt-2">
            <div
              className={
                lightTheme
                  ? "router-status poppins-medium-blackcustom-16px border-primary"
                  : "router-status poppins-medium-midnight-16px border-primary"
              }
            >
              Device Status
            </div>
            <div className="overlap-group-container">
              <OverlayTrigger placement="bottom" overlay={renderTooltip()}>
                <div className="overlap-group">
                  <div
                    className={
                      lightTheme
                        ? "poppins-medium-whitecustom-12px"
                        : "number poppins-medium-black-12px"
                    }
                  >
                    {agencyRouters?.components[0]?.id
                      ? agencyRouters?.components?.filter(
                          (router) =>
                            !router.bluetoothServiceStatus ||
                            !router.jarServiceStatus ||
                            !router.jarProcessStatus ||
                            !router.controllerStatus
                        )?.length
                      : 0}
                  </div>
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={renderTooltipActive()}
              >
                <div className="overlap-group1">
                  <div
                    className={
                      lightTheme
                        ? "poppins-medium-whitecustom-12px"
                        : "number poppins-medium-black-12px"
                    }
                  >
                    {
                      agencyRouters?.components?.filter(
                        (router) =>
                          router.bluetoothServiceStatus &&
                          router.jarServiceStatus &&
                          router.jarProcessStatus &&
                          router.controllerStatus
                      )?.length
                    }
                  </div>
                </div>
              </OverlayTrigger>
            </div>
          </div>
        </Link>
      ) : user?.userroles?.includes("Admin") ||
        user?.userroles?.includes("Viewer") ||
        user?.userroles?.includes("Medical") ||
        user?.userroles?.includes("Sensor Manager") ? (
        <Link
          to="/user/list_of_agencys_routers"
          style={{ textDecoration: "none" }}
        >
          <div className="group-861 mt-2">
            <div
              className={
                lightTheme
                  ? "router-status poppins-medium-blackcustom-16px border-primary"
                  : "router-status poppins-medium-midnight-16px border-primary"
              }
            >
              Device Status
            </div>
            <div className="overlap-group-container">
              <OverlayTrigger placement="bottom" overlay={renderTooltip()}>
                <div className="overlap-group">
                  <div
                    className={
                      lightTheme
                        ? "poppins-medium-whitecustom-12px"
                        : "number poppins-medium-black-12px"
                    }
                  >
                    {agencyRouters?.components[0]?.id
                      ? agencyRouters?.components?.filter(
                          (router) =>
                            !router.bluetoothServiceStatus ||
                            !router.jarServiceStatus ||
                            !router.jarProcessStatus ||
                            !router.controllerStatus
                        )?.length
                      : 0}
                  </div>
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={renderTooltipActive()}
              >
                <div className="overlap-group1">
                  <div
                    className={
                      lightTheme
                        ? "poppins-medium-whitecustom-12px"
                        : "number poppins-medium-black-12px"
                    }
                  >
                    {
                      agencyRouters?.components?.filter(
                        (router) =>
                          router.bluetoothServiceStatus &&
                          router.jarServiceStatus &&
                          router.jarProcessStatus &&
                          router.controllerStatus
                      )?.length
                    }
                  </div>
                </div>
              </OverlayTrigger>
            </div>
          </div>
        </Link>
      ) : (
        <div className="group-861 mt-2">
          <div
            className={
              lightTheme
                ? "router-status poppins-medium-blackcustom-16px border-primary"
                : "router-status poppins-medium-midnight-16px border-primary"
            }
          >
            Device Status
          </div>
          <div className="overlap-group-container">
            <OverlayTrigger placement="bottom" overlay={renderTooltip()}>
              <div className="overlap-group">
                <div
                  className={
                    lightTheme
                      ? "poppins-medium-whitecustom-12px"
                      : "number poppins-medium-black-12px"
                  }
                >
                  {agencyRouters?.components[0]?.id
                    ? agencyRouters?.components?.filter(
                        (router) =>
                          !router?.bluetoothServiceStatus ||
                          !router?.jarServiceStatus ||
                          !router?.jarProcessStatus ||
                          !router?.controllerStatus
                      )?.length
                    : 0}
                </div>
              </div>
            </OverlayTrigger>
            <div className="overlap-group1">
              <div
                className={
                  lightTheme
                    ? "poppins-medium-whitecustom-12px"
                    : "number poppins-medium-black-12px"
                }
              >
                {
                  agencyRouters?.components?.filter(
                    (router) =>
                      router?.bluetoothServiceStatus &&
                      router?.jarServiceStatus &&
                      router?.jarProcessStatus &&
                      router?.controllerStatus
                  )?.length
                }
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default RouterStatus;
