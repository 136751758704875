import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    // Default fallback URL if no returnTo path is found in appState
    const defaultRedirect = "/";

    // Get returnTo path from appState or use the default fallback
    const returnTo =
      appState && appState.returnTo ? appState.returnTo : defaultRedirect;

    // Navigate to the original path
    navigate(returnTo);
  };

  return (
    <Auth0Provider
      domain="login.custodyprotectreports.com"
      clientId="HbQJYxD866lki6fLwlS9icfBPtMD74xC"
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "https://4sightlabs.us.auth0.com/api/v2/",
        scope:
          "read:current_user update:current_user_metadata profile email read:roles update:roles",
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;
