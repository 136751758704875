import React from "react";
import { Button } from "react-bootstrap";

import "./login.css";
import { useAuth0 } from "@auth0/auth0-react";

function Login() {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = () => {
    loginWithRedirect({
      appState: { returnTo: window.location.pathname },
    });
  };

  return (
    <Button onClick={handleLogin} className="login-btn">
      Login
    </Button>
  );
}

export default Login;
