import React from "react";
import {
  Navbar,
  Nav,
  Container,
  Dropdown,
  Row,
  Form,
  Col,
} from "react-bootstrap";
import Login from "./Login";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { ArrowLeftSquare, Power } from "react-bootstrap-icons";
import "./navigationbar.css";
import RouterStatus from "./RouterStatus";

function NavigationBar({
  isAuthenticated,
  lightTheme,
  handleLightThemeToggle,
  userAppMetaData,
  agencyProfile,
  user,
  chosenAgency,
  chosenAgencysDomain,
}) {
  const { logout } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const toggleLightTheme = () => {
    handleLightThemeToggle();
  };

  const navColor = isAuthenticated
    ? lightTheme
      ? "rgba(255, 255, 255, 1)"
      : "#28272a"
    : "rgb(0, 0, 0)";

  const onBackClicked = () => {
    navigate(-1);
  };
  const onBackToDashboardClicked = () => {
    navigate(-1);
  };

  return (
    <Navbar
      defaultExpanded={true}
      style={{ backgroundColor: navColor }}
      expand="lg"
    >
      <Container fluid>
        <Navbar.Brand>
          {isAuthenticated && location.pathname.endsWith("/detailed_view") ? (
            lightTheme ? (
              <Row onClick={onBackClicked} style={{ cursor: "pointer" }}>
                <Col xs="auto" style={{ paddingRight: "5px" }}>
                  <ArrowLeftSquare size={30} />
                </Col>
                <Col xs="auto" style={{ paddingLeft: "5px" }}>
                  <div className="poppins-medium-romance-20px mt-1 text-black">
                    BACK
                  </div>
                </Col>
              </Row>
            ) : (
              <Row onClick={onBackClicked} style={{ cursor: "pointer" }}>
                <Col xs="auto" style={{ paddingRight: "5px" }}>
                  <ArrowLeftSquare size={30} className="text-white" />
                </Col>
                <Col xs="auto" style={{ paddingLeft: "5px" }}>
                  <div className="text-white poppins-medium-romance-20px mt-1">
                    BACK
                  </div>
                </Col>
              </Row>
            )
          ) : isAuthenticated && location.pathname.endsWith("/detainees") ? (
            lightTheme ? (
              <Row
                onClick={onBackToDashboardClicked}
                style={{ cursor: "pointer" }}
              >
                <Col xs="auto" style={{ paddingRight: "5px" }}>
                  <ArrowLeftSquare size={30} />
                </Col>
                <Col xs="auto" style={{ paddingLeft: "5px" }}>
                  <div className="poppins-medium-romance-20px mt-1 text-black">
                    BACK
                  </div>
                </Col>
              </Row>
            ) : (
              <Row
                onClick={onBackToDashboardClicked}
                style={{ cursor: "pointer" }}
              >
                <Col xs="auto" style={{ paddingRight: "5px" }}>
                  <ArrowLeftSquare size={30} className="text-white" />
                </Col>
                <Col xs="auto" style={{ paddingLeft: "5px" }}>
                  <div className="text-white poppins-medium-romance-20px mt-1">
                    BACK
                  </div>
                </Col>
              </Row>
            )
          ) : lightTheme ? (
            <img
              src="/4Sight_Labs_Logo_Black.svg"
              width="180"
              className="d-inline-block align-top mx-5"
              alt="Logo"
            />
          ) : (
            <img
              src="/4Sight_Labs_Logo_White.svg"
              width="180"
              className="d-inline-block align-top mx-5"
              alt="Logo"
            />
          )}
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          style={{
            backgroundColor: lightTheme
              ? "rgba(170, 170, 170, 0.8)"
              : "rgba(81, 81, 81, 0.8)",
          }}
        />

        <Navbar.Collapse id="basic-navbar-nav">
          {isAuthenticated &&
          (location.pathname.endsWith("/detailed_view") ||
            location.pathname.endsWith("/detainees")) ? (
            <></>
          ) : (
            <Nav className="me-auto">
              <Nav.Link
                as={Link}
                to="/"
                style={{ color: lightTheme ? "rgba(71,71,76,255)" : "#fff" }}
                className="mx-5 poppins-16px"
              >
                Home
              </Nav.Link>
              {user?.userroles?.includes("4SightAdmin") &&
              chosenAgencysDomain !== null &&
              user?.blocked !== true ? (
                <Nav.Link
                  as={Link}
                  to={`/admin/agency/${chosenAgency}/list_of_agencys_users`}
                  style={{ color: lightTheme ? "rgba(71,71,76,255)" : "#fff" }}
                  className="mx-5 poppins-16px"
                >
                  Users
                </Nav.Link>
              ) : user?.userroles?.includes("Admin") &&
                user?.blocked !== true ? (
                <Nav.Link
                  as={Link}
                  to="/user/list_of_agencys_users"
                  style={{ color: lightTheme ? "rgba(71,71,76,255)" : "#fff" }}
                  className="mx-5 poppins-16px"
                >
                  Users
                </Nav.Link>
              ) : (
                <></>
              )}
              {user?.userroles?.includes("4SightAdmin") ? (
                <Nav.Link
                  as={Link}
                  to="/admin/gen2dashboard"
                  style={{ color: lightTheme ? "rgba(71,71,76,255)" : "#fff" }}
                  className="mx-5 poppins-16px"
                >
                  Gen2
                </Nav.Link>
              ) : (
                <></>
              )}
            </Nav>
          )}
          <div className="d-flex align-items-center ms-auto flex-nowrap mx-5">
            {isAuthenticated ? (
              <React.Fragment>
                {user?.blocked !== true && user?.email_verified === true ? (
                  <Col className="">
                    <RouterStatus
                      userAppMetaData={userAppMetaData}
                      lightTheme={lightTheme}
                      agencyProfile={
                        agencyProfile !== null ? agencyProfile : null
                      }
                      user={user}
                      chosenAgency={chosenAgency}
                    />
                  </Col>
                ) : (
                  <></>
                )}
                <Col>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      id="dropdown-basic"
                      className="d-flex align-items-center text-light"
                      style={{ outline: "none" }}
                    >
                      <div
                        className={
                          lightTheme
                            ? "router-status poppins-medium-romance-16px-blackcustom"
                            : "router-status poppins-medium-romance-16px"
                        }
                        style={{ fontSize: "16px" }}
                      >
                        {user?.name ? user.name : "Unknown"}
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          localStorage.removeItem("userProfile");
                          localStorage.removeItem("userAgency");
                          localStorage.removeItem("isComponent1Active");
                          localStorage.removeItem("isComponent2Active");
                          logout({
                            logoutParams: {
                              returnTo: window.location.origin,
                            },
                          });
                        }}
                        style={{ width: "100%" }}
                      >
                        <Power size={20} className="mx-2" /> Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Row>
                    <Form className="mx-3">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label={lightTheme ? "Light" : "Dark"}
                        checked={lightTheme}
                        onChange={toggleLightTheme}
                        className={
                          lightTheme
                            ? "router-status poppins-medium-romance-16px-blackcustom"
                            : "router-status poppins-medium-romance-16px"
                        }
                      />
                    </Form>
                  </Row>{" "}
                </Col>
              </React.Fragment>
            ) : (
              <Login />
            )}
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
