import React, { useEffect, useState, lazy, Suspense } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import NavigationBar from "./Components/NavigationBar";
import "./Components/toastr.css";
import azureFunctionsService from "./Services/azureFunctionsService";
import { Alert } from "react-bootstrap";
import { Howl } from "howler";
import toastr from "toastr";

const CustodyProtectMonitor = lazy(() =>
  import("./Components/CustodyProtectMonitor")
);
const Gen2DemoDetailedView = lazy(() =>
  import("./Components/Gen2DemoDetailedView")
);
const Gen2DemoDashboard = lazy(() => import("./Components/Gen2DemoDashboard"));
const Gen2Demo = lazy(() => import("./Components/Gen2Demo"));
const NoAccess = lazy(() => import("./Components/NoAccess"));
const AgencyUserList = lazy(() => import("./Components/AgencyUserList"));
const AgencyRoutersList = lazy(() => import("./Components/AgencyRoutersList"));

const AdminAgenciesList = lazy(() => import("./Components/AdminAgenciesList"));
const EmailVerificationForm = lazy(() =>
  import("./Components/EmailVerificationForm")
);
const DetaineeDetailedView = lazy(() =>
  import("./Components/DetaineeDetailedView")
);
const Landing = lazy(() => import("./Components/Landing"));
const AddDetaineeToSensor = lazy(() =>
  import("./Components/AddDetaineeToSensor")
);
const YellowDetainees = lazy(() => import("./Components/YellowDetainees"));
const AgencyCreation = lazy(() => import("./Components/AgencyCreation"));
const RegisterRouter = lazy(() => import("./Components/RegisterRouter"));

function App() {
  const [usersAgency, setUsersAgency] = useState({ main: null });
  const [chosenAgency, setChosenAgency] = useState(null);
  const [chosenAgencysDomain, setChosenAgencysDomain] = useState(null);
  const [chosenAgencysName, setChosenAgencysName] = useState(null);
  const [usersProfile, setUsersProfile] = useState(null);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const { isAuthenticated, user, loginWithRedirect, isLoading, error } =
    useAuth0();
  const [lightTheme, setLightTheme] = useState(false);
  const location = useLocation();

  const [notifiedStatuses, setNotifiedStatuses] = useState({});
  const [soundEnabled, setSoundEnabled] = useState(false);
  const [subjects, setSubjects] = useState(null);

  useEffect(() => {
    const criticalAlertSound = new Howl({
      src: ["/sounds/AnyConv.com__critical-3.wav"],
    });

    const checkForCriticalSubjects = () => {
      subjects?.forEach((subject) => {
        const isCritical =
          subject?.custodyStateCurrent?.currentStatePriorityColor === "red";
        const wasCritical = notifiedStatuses[subject.id]?.wasCritical;
        const wasNotified = notifiedStatuses[subject.id]?.wasNotified;

        // Check if the subject is currently critical and either hasn't been notified before,
        // or was not critical last time we checked.
        if (isCritical && (!wasNotified || !wasCritical)) {
          if (
            "Notification" in window &&
            Notification.permission === "granted"
          ) {
            criticalAlertSound.play();
            new Notification("🚨 CRITICAL ALERT 🚨", {
              body: `Detainee is in critical state.`,
              requireInteraction: true,
            });

            // Update the notified statuses with the new state
            setNotifiedStatuses((prevStatuses) => ({
              ...prevStatuses,
              [subject.id]: { wasNotified: true, wasCritical: true },
            }));
          } else {
            if (soundEnabled) {
              criticalAlertSound.play();
              toastr.error("CRITICAL ALERT", "Detainee is in critical state.");
              setNotifiedStatuses((prevStatuses) => ({
                ...prevStatuses,
                [subject.id]: { wasNotified: true, wasCritical: true },
              }));
            }
          }
        } else if (wasCritical && !isCritical) {
          // If the subject is no longer critical, update the status to allow future notifications
          setNotifiedStatuses((prevStatuses) => ({
            ...prevStatuses,
            [subject.id]: { ...prevStatuses[subject.id], wasCritical: false },
          }));
        }
      });
    };

    if ("Notification" in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          checkForCriticalSubjects();
        }
      });
    } else {
      checkForCriticalSubjects();
    }
  }, [subjects, notifiedStatuses, soundEnabled]);

  useEffect(() => {
    const goOnline = () => setIsOnline(true);
    const goOffline = () => setIsOnline(false);

    window.addEventListener("online", goOnline);
    window.addEventListener("offline", goOffline);

    return () => {
      window.removeEventListener("online", goOnline);
      window.removeEventListener("offline", goOffline);
    };
  }, []);
  useEffect(() => {
    if (isAuthenticated && user && usersProfile === null) {
      const payload = { user_id: user?.sub };
      azureFunctionsService
        .getUsersProfile(payload)
        .then((response) => {
          setUsersProfile(response.data);
          localStorage.setItem("userProfile", JSON.stringify(response.data));
        })
        .catch(() => {});
    }
  }, [isAuthenticated, user, usersProfile]);

  useEffect(() => {
    if (isAuthenticated && user && usersProfile !== null) {
      const payload = { user_id: user?.sub };
      azureFunctionsService
        .getUsersProfile(payload)
        .then((response) => {
          console.log("RESPONSE", response);
          if (
            JSON.stringify(response?.data?.apmdata) !==
              JSON.stringify(usersProfile?.apmdata) ||
            response?.data?.blocked !== usersProfile?.blocked ||
            JSON.stringify(response?.data?.userroles) !==
              JSON.stringify(usersProfile?.userroles) ||
            JSON.stringify(response?.data?.user_id) !==
              JSON.stringify(usersProfile?.sub)
          ) {
            setUsersProfile(response.data);
            localStorage.setItem("userProfile", JSON.stringify(response.data));
          }
        })
        .catch((error) => {
          console.log("FAILED", error);
        });
    }
  }, [isAuthenticated, user, usersProfile, location]);

  useEffect(() => {
    const cachedUserProfile = localStorage.getItem("userProfile");
    const cachedUserAgency = localStorage.getItem("userAgency");

    if (cachedUserProfile) {
      setUsersProfile(JSON.parse(cachedUserProfile));
    }
    if (cachedUserAgency) {
      setUsersAgency((prevState) => {
        const copy = { ...prevState };
        copy.main = JSON.parse(cachedUserAgency);
        return copy;
      });
    }
  }, []);

  useEffect(() => {
    if (usersProfile !== null && usersProfile?.apmdata?.associatedDomain) {
      azureFunctionsService
        .getUsersAgency(
          usersProfile?.apmdata?.associatedDomain,
          usersProfile?.apmdata?.admToken
        )
        .then((response) => {
          setUsersAgency((prevState) => {
            const copy = { ...prevState };
            copy.main = response?.data;
            return copy;
          });
          localStorage.setItem("userAgency", JSON.stringify(response.data));
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (usersProfile !== null && usersProfile?.email) {
      const email = usersProfile?.email?.split("@");
      const domain = email[1];

      azureFunctionsService
        .getUsersAgency(domain, usersProfile?.apmdata?.admToken)
        .then((response) => {
          setUsersAgency((prevState) => {
            const copy = { ...prevState };
            copy.main = response?.data;
            return copy;
          });
          localStorage.setItem("userAgency", JSON.stringify(response.data));
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (usersProfile !== null && usersProfile?.name) {
      const email = usersProfile?.name?.split("@");
      const domain = email[1];

      azureFunctionsService
        .getUsersAgency(domain, usersProfile?.apmdata?.admToken)
        .then((response) => {
          setUsersAgency((prevState) => {
            const copy = { ...prevState };
            copy.main = response?.data;
            return copy;
          });
          localStorage.setItem("userAgency", JSON.stringify(response.data));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [usersProfile]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated && !error) {
      loginWithRedirect({ prompt: "none" });
    }
  }, [isLoading, isAuthenticated, error, loginWithRedirect]);

  useEffect(() => {
    const lightTheme = localStorage.getItem("lightTheme");
    if (lightTheme === "true") {
      setLightTheme(true);
    }
  }, []);
  useEffect(() => {
    if (usersProfile?.userroles?.includes("4SightAdmin")) {
      const storedChosenAgency = localStorage.getItem("chosenagency");
      const storedChosenAgencysDomain = localStorage.getItem(
        "chosenagencysdomain"
      );
      const storedChosenAgencysName = localStorage.getItem("chosenagencysname");

      if (
        storedChosenAgency &&
        storedChosenAgencysDomain &&
        storedChosenAgencysName
      ) {
        setChosenAgency(storedChosenAgency);
        setChosenAgencysDomain(storedChosenAgencysDomain);
        setChosenAgencysName(storedChosenAgencysName);
      }
    }
  }, [usersProfile?.userroles]);

  useEffect(() => {
    localStorage.setItem("lightTheme", lightTheme);
    if (lightTheme) {
      document.body.classList.add("light-theme");
    } else {
      document.body.classList.remove("light-theme");
    }
  }, [lightTheme]);

  const handleLightThemeToggle = () => {
    setLightTheme(!lightTheme);
  };
  return (
    <React.Fragment>
      <NavigationBar
        isAuthenticated={isAuthenticated}
        chosenAgency={chosenAgency}
        chosenAgencysDomain={chosenAgencysDomain}
        userAppMetaData={usersProfile?.apmdata ? usersProfile?.apmdata : null}
        agencyProfile={
          usersAgency?.main !== null && usersAgency?.main[0]
            ? usersAgency?.main[0]
            : null
        }
        lightTheme={lightTheme}
        user={usersProfile}
        handleLightThemeToggle={handleLightThemeToggle}
      />
      {!isOnline && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 10004,
          }}
        ></div>
      )}
      {!isOnline && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 10005,
            width: "50%",
            fontSize: "1.5rem",
            padding: "2rem",
          }}
        >
          <Alert variant="danger" className="poppins-14px text-center">
            Your internet connection is offline. Please check your connection.
          </Alert>
        </div>
      )}
      <Routes>
        {usersProfile?.email_verified === null ||
        undefined ||
        (usersProfile?.email_verified &&
          usersProfile?.email_verified === true) ? (
          isAuthenticated && usersProfile.blocked === true ? (
            <React.Fragment>
              <Route
                path="/"
                element={
                  <Suspense fallback={<h2>Loading...</h2>}>
                    <Landing
                      isAuthenticated={isAuthenticated}
                      user={usersProfile}
                      agencyProfile={
                        usersAgency?.main !== null && usersAgency?.main[0]
                          ? usersAgency?.main[0]
                          : null
                      }
                    />
                  </Suspense>
                }
                agencyProfile={
                  usersAgency?.main !== null && usersAgency?.main[0]
                    ? usersAgency?.main[0]
                    : null
                }
              />
              <Route path="*" element={<Navigate to="/" />} />
              <Route
                path="/user/no_access"
                element={
                  <Suspense fallback={<h2>Loading...</h2>}>
                    <NoAccess
                      isAuthenticated={isAuthenticated}
                      user={usersProfile}
                      lightTheme={lightTheme}
                      agencyProfile={
                        usersAgency?.main !== null && usersAgency?.main[0]
                          ? usersAgency?.main[0]
                          : null
                      }
                      userAppMetaData={
                        usersProfile?.apmdata ? usersProfile?.apmdata : null
                      }
                    />
                  </Suspense>
                }
              />
            </React.Fragment>
          ) : isAuthenticated &&
            usersProfile?.userroles?.includes("4SightAdmin") &&
            (usersProfile?.blocked === false || !usersProfile?.blocked) ? (
            <React.Fragment>
              <Route
                path="/"
                element={
                  <Suspense fallback={<h2>Loading...</h2>}>
                    <Landing
                      isAuthenticated={isAuthenticated}
                      user={usersProfile}
                      agencyProfile={
                        usersAgency?.main !== null && usersAgency?.main[0]
                          ? usersAgency?.main[0]
                          : null
                      }
                    />
                  </Suspense>
                }
              />
              <Route
                path="/admin/agencies_list"
                element={
                  <Suspense fallback={<h2>Loading...</h2>}>
                    <AdminAgenciesList
                      userAppMetaData={
                        usersProfile?.apmdata ? usersProfile?.apmdata : null
                      }
                      lightTheme={lightTheme}
                      user={usersProfile}
                      setChosenAgency={setChosenAgency}
                      chosenAgency={chosenAgency}
                      setChosenAgencysDomain={setChosenAgencysDomain}
                      chosenAgencysDomain={chosenAgencysDomain}
                      chosenAgencysName={chosenAgencysName}
                      setChosenAgencysName={setChosenAgencysName}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/admin/agency/:agencyId/dashboard"
                element={
                  <Suspense fallback={<h2>Loading...</h2>}>
                    <CustodyProtectMonitor
                      setSoundEnabled={setSoundEnabled}
                      soundEnabled={soundEnabled}
                      userAppMetaData={
                        usersProfile?.apmdata ? usersProfile?.apmdata : null
                      }
                      lightTheme={lightTheme}
                      user={usersProfile}
                      chosenAgencysName={chosenAgencysName}
                      setSubjects={setSubjects}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/admin/agency/:agencyId/detainees"
                element={
                  <Suspense fallback={<h2>Loading...</h2>}>
                    <YellowDetainees
                      isAuthenticated={isAuthenticated}
                      userAppMetaData={
                        usersProfile?.apmdata ? usersProfile?.apmdata : null
                      }
                      agencyProfile={
                        usersAgency?.main !== null && usersAgency?.main[0]
                          ? usersAgency?.main[0]
                          : null
                      }
                      user={usersProfile}
                      lightTheme={lightTheme}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/admin/agency/:agencyId/list_of_agencys_users"
                element={
                  <Suspense fallback={<h2>Loading...</h2>}>
                    <AgencyUserList
                      userAppMetaData={
                        usersProfile?.apmdata ? usersProfile?.apmdata : null
                      }
                      isAuthenticated={isAuthenticated}
                      chosenAgencysDomain={chosenAgencysDomain}
                      lightTheme={lightTheme}
                      user={usersProfile}
                      agencyProfile={
                        usersAgency?.main !== null && usersAgency?.main[0]
                          ? usersAgency?.main[0]
                          : null
                      }
                    />
                  </Suspense>
                }
              />
              <Route
                path="/admin/agency/:agencyId/list_of_agencys_routers"
                element={
                  <Suspense fallback={<h2>Loading...</h2>}>
                    <AgencyRoutersList
                      userAppMetaData={
                        usersProfile?.apmdata ? usersProfile?.apmdata : null
                      }
                      isAuthenticated={isAuthenticated}
                      lightTheme={lightTheme}
                      user={usersProfile}
                      agencyProfile={
                        usersAgency?.main !== null && usersAgency?.main[0]
                          ? usersAgency?.main[0]
                          : null
                      }
                    />
                  </Suspense>
                }
              />
              <Route
                path="/admin/agency/:agencyId/detainee/:custodyId/detailed_view"
                element={
                  <Suspense fallback={<h2>Loading...</h2>}>
                    <DetaineeDetailedView
                      isAuthenticated={isAuthenticated}
                      userAppMetaData={
                        usersProfile?.apmdata ? usersProfile?.apmdata : null
                      }
                      agencyProfile={
                        usersAgency?.main !== null && usersAgency?.main[0]
                          ? usersAgency?.main[0]
                          : null
                      }
                      user={usersProfile}
                      lightTheme={lightTheme}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/admin/connecting_gen2"
                element={
                  <Suspense fallback={<h2>Loading...</h2>}>
                    {" "}
                    <Gen2Demo
                      isAuthenticated={isAuthenticated}
                      userAppMetaData={
                        usersProfile?.apmdata ? usersProfile?.apmdata : null
                      }
                      user={usersProfile}
                      agencyProfile={
                        usersAgency?.main !== null && usersAgency?.main[0]
                          ? usersAgency?.main[0]
                          : null
                      }
                      handleLightThemeToggle={handleLightThemeToggle}
                      lightTheme={lightTheme}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/admin/gen2dashboard"
                element={
                  <Suspense fallback={<h2>Loading...</h2>}>
                    {" "}
                    <Gen2DemoDashboard
                      isAuthenticated={isAuthenticated}
                      userAppMetaData={
                        usersProfile?.apmdata ? usersProfile?.apmdata : null
                      }
                      user={usersProfile}
                      agencyProfile={
                        usersAgency?.main !== null && usersAgency?.main[0]
                          ? usersAgency?.main[0]
                          : null
                      }
                      handleLightThemeToggle={handleLightThemeToggle}
                      lightTheme={lightTheme}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/admin/gen2dashboard/detainee/:detaineeId/detailed_view"
                element={
                  <Suspense fallback={<h2>Loading...</h2>}>
                    {" "}
                    <Gen2DemoDetailedView
                      isAuthenticated={isAuthenticated}
                      userAppMetaData={
                        usersProfile?.apmdata ? usersProfile?.apmdata : null
                      }
                      user={usersProfile}
                      agencyProfile={
                        usersAgency?.main !== null && usersAgency?.main[0]
                          ? usersAgency?.main[0]
                          : null
                      }
                      handleLightThemeToggle={handleLightThemeToggle}
                      lightTheme={lightTheme}
                    />
                  </Suspense>
                }
              />
            </React.Fragment>
          ) : isAuthenticated &&
            usersProfile?.userroles?.includes("Admin") &&
            usersAgency?.main !== null &&
            usersAgency?.main[0] &&
            (usersProfile?.blocked === false || !usersProfile?.blocked) ? (
            <React.Fragment>
              <Route
                path="/"
                element={
                  <Suspense fallback={<h2>Loading...</h2>}>
                    <Landing
                      isAuthenticated={isAuthenticated}
                      user={usersProfile}
                      agencyProfile={
                        usersAgency?.main !== null && usersAgency?.main[0]
                          ? usersAgency?.main[0]
                          : null
                      }
                    />
                  </Suspense>
                }
              />
              <Route path="*" element={<Navigate to="/" />} />
              <Route
                path="/user/list_of_agencys_users"
                element={
                  <Suspense fallback={<h2>Loading...</h2>}>
                    <AgencyUserList
                      userAppMetaData={
                        usersProfile?.apmdata ? usersProfile?.apmdata : null
                      }
                      isAuthenticated={isAuthenticated}
                      lightTheme={lightTheme}
                      user={usersProfile}
                      agencyProfile={
                        usersAgency?.main !== null && usersAgency?.main[0]
                          ? usersAgency?.main[0]
                          : null
                      }
                    />
                  </Suspense>
                }
              />
              <Route
                path="/user/list_of_agencys_routers"
                element={
                  <Suspense fallback={<h2>Loading...</h2>}>
                    <AgencyRoutersList
                      userAppMetaData={
                        usersProfile?.apmdata ? usersProfile?.apmdata : null
                      }
                      isAuthenticated={isAuthenticated}
                      lightTheme={lightTheme}
                      user={usersProfile}
                      agencyProfile={
                        usersAgency?.main !== null && usersAgency?.main[0]
                          ? usersAgency?.main[0]
                          : null
                      }
                    />
                  </Suspense>
                }
              />
              <Route
                path="/user/dashboard"
                element={
                  <Suspense fallback={<h2>loading...</h2>}>
                    <CustodyProtectMonitor
                      setSubjects={setSubjects}
                      setSoundEnabled={setSoundEnabled}
                      soundEnabled={soundEnabled}
                      isAuthenticated={isAuthenticated}
                      userAppMetaData={
                        usersProfile?.apmdata ? usersProfile?.apmdata : null
                      }
                      user={usersProfile}
                      agencyProfile={
                        usersAgency?.main !== null && usersAgency?.main[0]
                          ? usersAgency?.main[0]
                          : null
                      }
                      handleLightThemeToggle={handleLightThemeToggle}
                      lightTheme={lightTheme}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/user/connect_sensor_to_detainee"
                element={
                  <Suspense fallback={<h2>Loading...</h2>}>
                    {" "}
                    <AddDetaineeToSensor
                      isAuthenticated={isAuthenticated}
                      userAppMetaData={
                        usersProfile?.apmdata ? usersProfile?.apmdata : null
                      }
                      user={usersProfile}
                      agencyProfile={
                        usersAgency?.main !== null && usersAgency?.main[0]
                          ? usersAgency?.main[0]
                          : null
                      }
                      handleLightThemeToggle={handleLightThemeToggle}
                      lightTheme={lightTheme}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/s/:id"
                element={
                  <Suspense fallback={<h2>Loading...</h2>}>
                    {" "}
                    <AddDetaineeToSensor
                      isAuthenticated={isAuthenticated}
                      userAppMetaData={
                        usersProfile?.apmdata ? usersProfile?.apmdata : null
                      }
                      user={usersProfile}
                      agencyProfile={
                        usersAgency?.main !== null && usersAgency?.main[0]
                          ? usersAgency?.main[0]
                          : null
                      }
                      handleLightThemeToggle={handleLightThemeToggle}
                      lightTheme={lightTheme}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/user/detainees"
                element={
                  <Suspense fallback={<h2>Loading...</h2>}>
                    <YellowDetainees
                      isAuthenticated={isAuthenticated}
                      userAppMetaData={
                        usersProfile?.apmdata ? usersProfile?.apmdata : null
                      }
                      agencyProfile={
                        usersAgency?.main !== null && usersAgency?.main[0]
                          ? usersAgency?.main[0]
                          : null
                      }
                      user={usersProfile}
                      lightTheme={lightTheme}
                    />
                  </Suspense>
                }
              />{" "}
              <Route
                path="/user/register_router/:macId"
                element={
                  <Suspense fallback={<h2>Loading...</h2>}>
                    {" "}
                    <RegisterRouter
                      userAppMetaData={
                        usersProfile?.apmdata ? usersProfile?.apmdata : null
                      }
                      user={usersProfile}
                      agencyProfile={
                        usersAgency?.main !== null && usersAgency?.main[0]
                          ? usersAgency?.main[0]
                          : null
                      }
                      lightTheme={lightTheme}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/user/detainee/:custodyId/detailed_view"
                element={
                  <Suspense fallback={<h2>Loading...</h2>}>
                    {" "}
                    <DetaineeDetailedView
                      userAppMetaData={
                        usersProfile?.apmdata ? usersProfile?.apmdata : null
                      }
                      user={usersProfile}
                      agencyProfile={
                        usersAgency?.main !== null && usersAgency?.main[0]
                          ? usersAgency?.main[0]
                          : null
                      }
                      lightTheme={lightTheme}
                    />
                  </Suspense>
                }
              />
            </React.Fragment>
          ) : isAuthenticated &&
            (usersProfile?.userroles?.includes("Medical") ||
              usersProfile?.userroles?.includes("Sensor Manager")) &&
            usersAgency?.main !== null &&
            usersAgency?.main[0] &&
            (usersProfile?.blocked === false || !usersProfile?.blocked) ? (
            <React.Fragment>
              <Route
                path="/"
                element={
                  <Suspense fallback={<h2>Loading...</h2>}>
                    <Landing
                      isAuthenticated={isAuthenticated}
                      user={usersProfile}
                      agencyProfile={
                        usersAgency?.main !== null && usersAgency?.main[0]
                          ? usersAgency?.main[0]
                          : null
                      }
                    />
                  </Suspense>
                }
              />
              <Route path="*" element={<Navigate to="/" />} />
              <Route
                path="/user/dashboard"
                element={
                  <Suspense fallback={<h2>loading...</h2>}>
                    <CustodyProtectMonitor
                      setSubjects={setSubjects}
                      setSoundEnabled={setSoundEnabled}
                      soundEnabled={soundEnabled}
                      isAuthenticated={isAuthenticated}
                      userAppMetaData={
                        usersProfile?.apmdata ? usersProfile?.apmdata : null
                      }
                      user={usersProfile}
                      agencyProfile={
                        usersAgency?.main !== null && usersAgency?.main[0]
                          ? usersAgency?.main[0]
                          : null
                      }
                      handleLightThemeToggle={handleLightThemeToggle}
                      lightTheme={lightTheme}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/user/connect_sensor_to_detainee"
                element={
                  <Suspense fallback={<h2>Loading...</h2>}>
                    {" "}
                    <AddDetaineeToSensor
                      isAuthenticated={isAuthenticated}
                      userAppMetaData={
                        usersProfile?.apmdata ? usersProfile?.apmdata : null
                      }
                      user={usersProfile}
                      agencyProfile={
                        usersAgency?.main !== null && usersAgency?.main[0]
                          ? usersAgency?.main[0]
                          : null
                      }
                      handleLightThemeToggle={handleLightThemeToggle}
                      lightTheme={lightTheme}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/s/:id"
                element={
                  <Suspense fallback={<h2>Loading...</h2>}>
                    {" "}
                    <AddDetaineeToSensor
                      isAuthenticated={isAuthenticated}
                      userAppMetaData={
                        usersProfile?.apmdata ? usersProfile?.apmdata : null
                      }
                      user={usersProfile}
                      agencyProfile={
                        usersAgency?.main !== null && usersAgency?.main[0]
                          ? usersAgency?.main[0]
                          : null
                      }
                      handleLightThemeToggle={handleLightThemeToggle}
                      lightTheme={lightTheme}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/user/detainees"
                element={
                  <Suspense fallback={<h2>Loading...</h2>}>
                    <YellowDetainees
                      isAuthenticated={isAuthenticated}
                      userAppMetaData={
                        usersProfile?.apmdata ? usersProfile?.apmdata : null
                      }
                      agencyProfile={
                        usersAgency?.main !== null && usersAgency?.main[0]
                          ? usersAgency?.main[0]
                          : null
                      }
                      user={usersProfile}
                      lightTheme={lightTheme}
                    />
                  </Suspense>
                }
              />{" "}
              <Route
                path="/user/detainee/:custodyId/detailed_view"
                element={
                  <Suspense fallback={<h2>Loading...</h2>}>
                    {" "}
                    <DetaineeDetailedView
                      userAppMetaData={
                        usersProfile?.apmdata ? usersProfile?.apmdata : null
                      }
                      user={usersProfile}
                      agencyProfile={
                        usersAgency?.main !== null && usersAgency?.main[0]
                          ? usersAgency?.main[0]
                          : null
                      }
                      lightTheme={lightTheme}
                    />
                  </Suspense>
                }
              />
            </React.Fragment>
          ) : (
            isAuthenticated &&
            (usersProfile?.userroles?.includes("Viewer") &&
            usersAgency?.main !== null &&
            usersAgency?.main[0] &&
            (usersProfile?.blocked === false || !usersProfile?.blocked) ? (
              <React.Fragment>
                <Route
                  path="/"
                  element={
                    <Suspense fallback={<h2>Loading...</h2>}>
                      <Landing
                        isAuthenticated={isAuthenticated}
                        user={usersProfile}
                        agencyProfile={
                          usersAgency?.main !== null && usersAgency?.main[0]
                            ? usersAgency?.main[0]
                            : null
                        }
                      />
                    </Suspense>
                  }
                />
                <Route path="*" element={<Navigate to="/" />} />
                <Route
                  path="/user/dashboard"
                  element={
                    <Suspense fallback={<h2>loading...</h2>}>
                      <CustodyProtectMonitor
                        setSubjects={setSubjects}
                        setSoundEnabled={setSoundEnabled}
                        soundEnabled={soundEnabled}
                        isAuthenticated={isAuthenticated}
                        userAppMetaData={
                          usersProfile?.apmdata ? usersProfile?.apmdata : null
                        }
                        user={usersProfile}
                        agencyProfile={
                          usersAgency?.main !== null && usersAgency?.main[0]
                            ? usersAgency?.main[0]
                            : null
                        }
                        handleLightThemeToggle={handleLightThemeToggle}
                        lightTheme={lightTheme}
                      />
                    </Suspense>
                  }
                />
                <Route
                  path="/user/detainees"
                  element={
                    <Suspense fallback={<h2>Loading...</h2>}>
                      <YellowDetainees
                        isAuthenticated={isAuthenticated}
                        userAppMetaData={
                          usersProfile?.apmdata ? usersProfile?.apmdata : null
                        }
                        agencyProfile={
                          usersAgency?.main !== null && usersAgency?.main[0]
                            ? usersAgency?.main[0]
                            : null
                        }
                        user={usersProfile}
                        lightTheme={lightTheme}
                      />
                    </Suspense>
                  }
                />{" "}
                <Route
                  path="/user/detainee/:custodyId/detailed_view"
                  element={
                    <Suspense fallback={<h2>Loading...</h2>}>
                      {" "}
                      <DetaineeDetailedView
                        userAppMetaData={
                          usersProfile?.apmdata ? usersProfile?.apmdata : null
                        }
                        user={usersProfile}
                        agencyProfile={
                          usersAgency?.main !== null && usersAgency?.main[0]
                            ? usersAgency?.main[0]
                            : null
                        }
                        lightTheme={lightTheme}
                      />
                    </Suspense>
                  }
                />
              </React.Fragment>
            ) : isAuthenticated &&
              usersAgency?.main !== null &&
              usersAgency?.main[0] &&
              usersProfile?.userroles?.length === 0 ? (
              <React.Fragment>
                <Route
                  path="/"
                  element={
                    <Suspense fallback={<h2>Loading...</h2>}>
                      <Landing
                        isAuthenticated={isAuthenticated}
                        user={usersProfile}
                        agencyProfile={
                          usersAgency?.main !== null && usersAgency?.main[0]
                            ? usersAgency?.main[0]
                            : null
                        }
                      />
                    </Suspense>
                  }
                  agencyProfile={
                    usersAgency?.main !== null && usersAgency?.main[0]
                      ? usersAgency?.main[0]
                      : null
                  }
                />
                <Route path="*" element={<Navigate to="/" />} />
                <Route
                  path="/user/no_access"
                  element={
                    <Suspense fallback={<h2>Loading...</h2>}>
                      <NoAccess
                        isAuthenticated={isAuthenticated}
                        user={usersProfile}
                        lightTheme={lightTheme}
                        agencyProfile={
                          usersAgency?.main !== null && usersAgency?.main[0]
                            ? usersAgency?.main[0]
                            : null
                        }
                        userAppMetaData={
                          usersProfile?.apmdata ? usersProfile?.apmdata : null
                        }
                      />
                    </Suspense>
                  }
                />
              </React.Fragment>
            ) : isAuthenticated &&
              usersAgency?.main !== null &&
              usersAgency?.main?.length === 0 ? (
              <React.Fragment>
                <Route
                  path="/"
                  element={
                    <Suspense fallback={<h2>Loading...</h2>}>
                      <Landing
                        isAuthenticated={isAuthenticated}
                        user={usersProfile}
                      />
                    </Suspense>
                  }
                  agencyProfile={
                    usersAgency?.main !== null && usersAgency?.main[0]
                      ? usersAgency?.main[0]
                      : null
                  }
                />
                <Route path="*" element={<Navigate to="/" />} />
                <Route
                  path="/user/agencycreation"
                  element={
                    <Suspense fallback={<h2>Loading...</h2>}>
                      <AgencyCreation
                        isAuthenticated={isAuthenticated}
                        user={usersProfile}
                        lightTheme={lightTheme}
                        userAppMetaData={
                          usersProfile?.apmdata ? usersProfile?.apmdata : null
                        }
                        setUsersAgency={setUsersAgency}
                      />
                    </Suspense>
                  }
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Route
                  path="/"
                  element={
                    <Suspense fallback={<h2>Loading...</h2>}>
                      <Landing
                        isAuthenticated={isAuthenticated}
                        user={usersProfile}
                        agencyProfile={
                          usersAgency?.main !== null && usersAgency?.main[0]
                            ? usersAgency?.main[0]
                            : null
                        }
                      />
                    </Suspense>
                  }
                />
              </React.Fragment>
            ))
          )
        ) : (
          <React.Fragment>
            <Route
              path="/"
              element={
                <Suspense fallback={<h2>Loading...</h2>}>
                  <Landing
                    isAuthenticated={isAuthenticated}
                    user={usersProfile}
                    agencyProfile={
                      usersAgency?.main !== null && usersAgency?.main[0]
                        ? usersAgency?.main[0]
                        : null
                    }
                  />
                </Suspense>
              }
            />

            <Route
              path="/user/verify_email"
              element={
                <Suspense fallback={<h2>Loading...</h2>}>
                  <EmailVerificationForm
                    isAuthenticated={isAuthenticated}
                    user={usersProfile}
                    userAppMetaData={
                      usersProfile?.apmdata ? usersProfile?.apmdata : null
                    }
                    lightTheme={lightTheme}
                  />
                </Suspense>
              }
            />
          </React.Fragment>
        )}{" "}
      </Routes>
    </React.Fragment>
  );
}

export default App;
