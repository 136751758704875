import axios from "axios";

const baseUrl = "https://cpreportsjstrigger.azurewebsites.net";

const GetSensorRegistryByAgencyId = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/GetSensorRegistryByAgencyId`,
    withCredentials: true,
    data: payload,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const GetAgencyPreferences = (agencyId, token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/api/GetAgencyPreferences?agencyId=${agencyId}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const GetDetaineesJmsData = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/GetDetaineesJmsData`,
    withCredentials: true,
    data: payload,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const GetAgencysSitesLocations = (agencyId, token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/api/GetAgencysSitesLocations?agencyId=${agencyId}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const GetAllJmsDetaineesByAgencyId = (agencyId, token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/api/GetAllJmsDetaineesByAgencyId?agencyId=${agencyId}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const CreateSensorRegistryFirebaseDocument = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/CreateSensorRegistryFirebaseDocument`,
    withCredentials: true,
    data: payload,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const CreateDetaineeCustodyRegistryDocument = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/CreateDetaineeCustodyRegistryDocument`,
    withCredentials: true,
    data: payload,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const RemoveSensorDetaineeConnection = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/RemoveSensorDetaineeConnection`,
    withCredentials: true,
    data: payload,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

const getUsersAgency = (emailDomain, token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/api/GetUsersAgency?emailDomain=${emailDomain}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const createAgency = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/CreateAgency`,
    withCredentials: true,
    data: payload,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const getSensorById = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/GetSensorById`,
    withCredentials: true,
    data: payload,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const createRouterRegistry = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/CreateRouterRegistry`,
    withCredentials: true,
    data: payload,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const getSensorRegistryByCustodyId = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/GetSensorRegistryByCustodyId`,
    withCredentials: true,
    data: payload,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const getDetaineesHrDataByTimeSet = (sensorId, minutes, custodyId, token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/api/GetDetaineesHrDataByTimeSet?sensorId=${sensorId}&minutes=${minutes}&custodyId=${custodyId}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

const resendEmailVerification = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/ResendEmailVerification`,
    withCredentials: true,
    data: payload,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

const changeHRThreshold = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/ChangeHRThreshold`,
    withCredentials: true,
    data: payload,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const getAllAgencies = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/GetAllAgencies`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const getAgenciesBySearch = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/GetAgencyBySearch`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const pinADetainee = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/PinADetainee`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const unpinADetainee = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/UnpinADetainee`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const getPinnedDetainees = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/GetPinnedDetainees`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

const createJmsDetainee = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/CreateJmsDetainee`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

const resetAlert = (payload) => {
  const config = {
    method: "POST",
    url: "https://demo-custodyprotect-firebase.azurewebsites.net/api/appUserAction?code=1m8heqiLex29d_uMjfsxpTO34fbS7s5fqsfOtsfmYlucAzFutw-XOA==",
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config);
};

const getAgencysRouters = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/GetAgencysRouters`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

const getUsersRoles = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/GetUsersRoles`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const getUsersByEmailDomain = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/GetUsersByEmailDomain`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const assignAdminRoleToUser = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/AssignAdminRoleToUser`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const assignMedicalRoleToUser = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/AssignMedicalRoleToUser`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

const assignViewerRoleToUser = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/AssignViewerRoleToUser`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const assignSensorManagerRoleToUser = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/AssignSensorManagerRoleToUser`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const removeAdminRoleFromUser = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/RemoveAdminRoleFromUser`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const removeMedicalRoleFromUser = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/RemoveMedicalRoleFromUser`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const removeViewerRoleFromUser = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/RemoveViewerRoleFromUser`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const removeSensorManagerRoleFromUser = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/RemoveSensorManagerRoleFromUser`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const updateRouterRegistry = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/UpdateRouterRegistry`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const getUsersProfile = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/GetUsersProfile`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

const getHrThresholdProfile = (documentId, token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/api/GetHrThresholdProfile?documentId=${documentId}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

const createHrThresholdProfile = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/CreateHrThresholdProfile`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

const setHrThresholdProfileAsDefault = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/SetHrThresholdProfileAsDefault`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const blockUserAccess = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/BlockUserAccess`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const unblockUserAccess = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/UnblockUserAccess`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const createUpdateConnection = (payload) => {
  const config = {
    method: "POST",
    url: "https://cpjstriggergen2test.azurewebsites.net/api/CreateUpdateConnection",
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config);
};

const getAllConnectedDevices = (payload) => {
  const config = {
    method: "GET",
    url: "https://cpjstriggergen2test.azurewebsites.net/api/GetAllConnectedDevices",
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config);
};

const getDetaineesJmsDataById = (detaineeId) => {
  const config = {
    method: "GET",
    url: `https://cpjstriggergen2test.azurewebsites.net/api/GetDetaineesJmsDataById?detaineeId=${detaineeId}`,

    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config);
};

const getConnectedDeviceJmsId = (jmsId) => {
  const config = {
    method: "GET",
    url: `https://cpjstriggergen2test.azurewebsites.net/api/GetConnectedDeviceJmsId?jmsId=${jmsId}`,

    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config);
};

const getHistoryDataByDeviceId = (deviceId, startTime) => {
  const config = {
    method: "GET",
    url: `https://cpjstriggergen2test.azurewebsites.net/api/GetHistoryDataByDeviceId?deviceId=${deviceId}&startTime=${startTime}`,

    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config);
};
const stopConnection = (payload) => {
  const config = {
    method: "POST",
    url: `https://cpjstriggergen2test.azurewebsites.net/api/StopConnection`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config);
};

const getHrThreshold = () => {
  const config = {
    method: "GET",
    url: `https://cpjstriggergen2test.azurewebsites.net/api/GetHrThreshold`,

    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config);
};
const updateHrThreshold = (payload) => {
  const config = {
    method: "POST",
    url: `https://cpjstriggergen2test.azurewebsites.net/api/UpdateHrThreshold`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config);
};

const addUserToAzureSignalRGroup = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/addUserToAzureSignalRGroup`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const removeUserFromAzureSignalRGroup = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/RemoveUserFromAzureSignalRGroup`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

const getConnectionInfo = () => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/negotiate`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config);
};

const getActiveSensorById = (sensorId, token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/api/getActiveSensorBySensorId?sensorId=${sensorId}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

const isSensorActive = (sensorId, token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/api/isSensorActive?sensorId=${sensorId}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

const checkoutSensor = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/CheckoutSensor`,
    withCredentials: true,
    data: payload,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const checkoutSensorUnknownJms = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/CheckoutSensorUnknownJms`,
    withCredentials: true,
    data: payload,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

const getDetaineesStateHistory = (custodyId, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/getDetaineesStateHistory?custodyId=${custodyId}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const UpdateDetaineesJmsData = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/UpdateDetaineesJmsData`,
    withCredentials: true,
    data: payload,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

const getGpsRouter = (agencyId, site, location, token) => {
  const config = {
    method: "GET",
    url: `${baseUrl}/api/getGpsRouter?agencyId=${agencyId}&site=${site}&location=${location}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const AssignJmsDetainee = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/AssignJmsDetainee`,
    withCredentials: true,
    data: payload,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const assignCheckoutSensorsToUser = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/AssigningCheckoutSensorsToUser`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const getLastHr = (payload) => {
  const config = {
    method: "POST",
    url: `https://custodyreportstrigger.azurewebsites.net/api/getLastHR?code=I-BmlYkJ0yKKSfsfj5DZXxAY3cD6R4hNJ5kPTC1pET2jAzFu4800Uw==`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config);
};

const getLastRouterStatusDoc = (payload) => {
  const config = {
    method: "POST",
    url: `https://custodyreportstrigger.azurewebsites.net/api/lastRouterSeen?code=aRO3R0WMgisXN9useaq3BT_PJnjq9_4Q7metWx5M66xqAzFuAmkS6Q==`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config);
};

const azureFunctionsService = {
  getLastRouterStatusDoc,
  getLastHr,
  assignCheckoutSensorsToUser,
  removeUserFromAzureSignalRGroup,
  AssignJmsDetainee,
  getGpsRouter,
  UpdateDetaineesJmsData,
  getDetaineesStateHistory,
  checkoutSensorUnknownJms,
  checkoutSensor,
  isSensorActive,
  getActiveSensorById,
  getConnectionInfo,
  addUserToAzureSignalRGroup,
  updateHrThreshold,
  getHrThreshold,
  stopConnection,
  getHistoryDataByDeviceId,
  getConnectedDeviceJmsId,
  getDetaineesJmsDataById,
  getAllConnectedDevices,
  createUpdateConnection,
  blockUserAccess,
  unblockUserAccess,
  setHrThresholdProfileAsDefault,
  createHrThresholdProfile,
  getHrThresholdProfile,
  getUsersProfile,
  updateRouterRegistry,
  removeSensorManagerRoleFromUser,
  removeViewerRoleFromUser,
  removeMedicalRoleFromUser,
  removeAdminRoleFromUser,
  assignSensorManagerRoleToUser,
  assignAdminRoleToUser,
  assignMedicalRoleToUser,
  assignViewerRoleToUser,
  getUsersByEmailDomain,
  getUsersRoles,
  getAgencysRouters,
  createJmsDetainee,
  resetAlert,
  getPinnedDetainees,
  unpinADetainee,
  pinADetainee,
  getAllAgencies,
  getAgenciesBySearch,
  changeHRThreshold,
  resendEmailVerification,
  getDetaineesHrDataByTimeSet,
  getSensorRegistryByCustodyId,
  createRouterRegistry,
  getSensorById,
  createAgency,
  getUsersAgency,
  RemoveSensorDetaineeConnection,
  CreateDetaineeCustodyRegistryDocument,
  CreateSensorRegistryFirebaseDocument,
  GetAllJmsDetaineesByAgencyId,
  GetSensorRegistryByAgencyId,
  GetAgencyPreferences,
  GetDetaineesJmsData,
  GetAgencysSitesLocations,
};

export default azureFunctionsService;
