import axios from "axios";

const baseUrl = "https://cpreportsjstrigger.azurewebsites.net";

const getAllRouters = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/GetAllRouters`,
    withCredentials: true,
    data: payload,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const getAllRouterRegistries = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/GetAllRouterRegistries`,
    withCredentials: true,
    data: payload,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const getAllAgencies = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/GetAllAgencies`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const getAgenciesBySearch = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/GetAgencyBySearch`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const getAllActiveRouters = (payload, token) => {
  const config = {
    method: "POST",
    url: `${baseUrl}/api/GetAllActiveRouters`,
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

const routerService = {
  getAllActiveRouters,
  getAllRouters,
  getAllRouterRegistries,
  getAllAgencies,
  getAgenciesBySearch,
};
export default routerService;
